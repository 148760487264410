import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom'
import './index.scss';
import service from '../../public/service';
import {FaCar, FaSuperpowers, FaChargingStation} from 'react-icons/fa'


//https://www.figma.com/file/MUPrnWpC5aUAcsqoBjtQ3v/EV-Website-Design-Template-(Community)?type=design&node-id=15-58&mode=design&t=vgRhJS5HTjvwG518-0
class Aboutus extends Component {
  constructor(props) {
    super(props)
    this.state={
      offsetY : false
    }
  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
    
  }

  render() {
    const {i18n, brands} = this.props
    /*
    <a href="https://www.freepik.com/free-vector/electric-vehicles-isometric-design-concept_6438374.htm#fromView=search&page=1&position=23&uuid=0325113b-cc70-4541-ac41-ae1ea574cce9">Image by macrovector on Freepik</a>
    */
    return (
      <div className={["Aboutus"].join(" ")}>

        <div className={["Content", "P1"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <div className={["Title"].join(" ")}> {i18n("CU_Header1")} </div>  
            <p className={["Message"]}>{i18n("CU_Msg1")}</p>
          </div>
        </div>

        <div className={["Content", "P2"].join(" ")}>  
          <div className={["Section"].join(" ")}>
            <img src={service.imageLocate("aboutus/ev_base.jpg") } className='Img'/>
          </div>
        </div>

        <div className={["Content", "P3"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <div className={["Title"].join(" ")}> { i18n("CU_Header2") } </div>  
            <div className={["Sub-Title"].join(" ")}> { i18n("CU_Sub_Header2") } </div>  
            <p className={["Message"]}>{i18n("CU_Msg2")}</p>
          </div>
        </div>

        <div className={["Content", "P4"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <img src={service.imageLocate("aboutus/green_bus.webp") } className='Img'/>
          </div>
        </div>

        <div className={["Content", "P5"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <div className={["Title"].join(" ")}> { i18n("CU_Header3") } </div>  
            {
              ["1","2","3"].map( (v,k)=> {
                return (
                  <div key={k}>
                    <div className={["Title", "Sub"].join(" ")}> { i18n(`CU_Sub_Header3_${v}`) } </div>  
                    <div className={["Sub-Title"].join(" ")}> { i18n(`CU_Sub_Sub_Header3_${v}`) } </div> 
                    <p className={["Message"]}>{i18n(`CU_Msg3_${v}`)}</p>
                  </div>
                )
              })
            }
            
            
          </div>
        </div>

        

      </div>
    )
  }

}

export default Aboutus;