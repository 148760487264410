import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom'
import './index.scss';
import service from '../../public/service';
import { FiXCircle } from "react-icons/fi";


//https://www.figma.com/file/MUPrnWpC5aUAcsqoBjtQ3v/EV-Website-Design-Template-(Community)?type=design&node-id=15-58&mode=design&t=vgRhJS5HTjvwG518-0
class Popup extends Component {
  constructor(props) {
    super(props)
    this.state={
 
    }
  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
    
  }

  render() {
    const {i18n, url, updatevalue} = this.props

    if(url) {
      return(
        <div className="Popup" style={{zIndex : 99}}>
          <div className={["Control"].join(" ")} >  
            <div className={["Close"].join(" ")} onClick={
            () => updatevalue({url : null})
          }>
              <FiXCircle className={["Icon"].join(" ")}/>
              <div className={["Text"].join(" ")}> { i18n("close") } </div>
              </div>
          </div>
          <iframe className={["IFrame"].join(" ")} src={url} />
        </div>
    )
  } else {
    return null
  }
  }

}

export default Popup;