import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Link } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link';
import { FaBars, FaTimes, FaUser } from 'react-icons/fa';
import Lottie from "lottie-react";
import service from '../../public/service';
//import Logo from "../../assets/lottie/logo.json";
import Shop from "../../assets/lottie/eshop.json";

//require('react-web-vector-icons/fonts');

class Header extends Component {
  constructor(props) {
    super(props)
    this.state={
      offsetY : false,
      show : false,
      loop : false
    }
  }
  
  componentDidMount() {
    //var elem = document.getElementById("header_bar")
    //var header_bar = elem.offsetHeight
    //console.log( window.location )

    var header_bar = 50
    window.onscroll = () => {
      if(  (window.pageYOffset > header_bar) && (this.state.offsetY === false ) ) {
        this.setState({
          offsetY : true
        })
      } else if(  (window.pageYOffset <= header_bar) && ( this.state.offsetY === true ) ) {
        this.setState({
          offsetY : false
        })
      } 
    }
  }



  render() {
    const {loop} = this.state
    const {i18n, language, updatevalue, languages} = this.props


    var showHeader = true
    for (var s of service.header_excecptions) {
      if( window.location.pathname.indexOf(s) > -1 ) {
        showHeader = false
        break
      }
    }
    var zero_content = service.header_zero_content.includes( window.location.pathname )

    return(
      <div className={["Header", this.state.offsetY?"Offset":"" ].join(" ")} id={"header"}>
        <div className={["Large-Frame"].join(" ") } >

            <div className={["Logo-Frame"].join(" ")}>
              { 
                <img 
                  src={ !this.state.offsetY?window.logo_w:window.logo_wc } 
                  className={["Logo"].join(" ")}/>
              }
              { /*
                <div className={["Logo-Name"].join(" ")}> { i18n("name") } </div>
            */}
            </div>


          <div className={["Lower"].join(" ")} id="header_bar" >
            <div className={["Lower-Frame"].join(" ")}>
              {
                this.props.content && !zero_content &&
                this.props.content.map( (v,k) => {
                  //if( this.props.content[v].header === true  ) {
                    if( v.link ) {
                      return(
                        <a
                          href={v.link}
                          key={"A_"+k}
                          target="_blank"
                          className={["Item", (this.props.selected===v['key'])?"Selected":""].join(" ")}
                        > 
                          {  i18n(v['key'])  } 
                        </a>
                      )
                    } else {

                        return (
                          <Link 
                            onClick={()=>{  
                              window.scrollTo(0,0) 
                              this.props.updatevalue({ selected  : v['key']})
                            }}
                            to={  v['key']  }
                            key={"L_"+k}
                            className={["Item", (this.props.selected===v['key'])?"Selected":""].join(" ")}>
                              {  i18n(v['key'])  }
                          </Link>
                        )

                    }
                } )
              }
              





              

            </div>
          </div>
        </div>
        <div className={['Languages']} >
            {
              languages.map(  
                (v, k) => {
                  if(this.props.language===v.key)  {
                    return null
                  } else {
                    return (
                      <div 
                        key={k} 
                        onClick={()=>{
                          this.setState({
                            show : false
                          })
                          this.props.updatevalue({language : v.key})
                        }}
                        className={['Option'].join(" ")}>
                        {  v['value'] }
                      </div>
                    )
                  }
                }
              )
            }
        </div>

        <div className={["Small-Frame"].join(" ") } >
          <div className={['Nav-Bar'].join(' ')} >
            { !this.state.show &&
              <FaBars className={['Icon'].join(" ")} onClick={ (e)=>{  this.setState({  show : true}) } } />
            }
            { this.state.show &&
              <FaTimes className={['Icon'].join(" ")} onClick={ (e)=>{  this.setState({  show : false}) } } />
            }
            <img 
              src={ window.logo_wc } 
              className={["Logo"].join(" ")}/>
            <div className={["Pad"].join(" ")}></div>
          </div>
          <div className={ ['Nav-Menu', this.state.show?"Show":""].join(" ")  }>
            {
                this.props.content && !zero_content &&
                this.props.content.map( (v,k) => {
                    if( v['link']) {
                      return (
                        <a
                          onClick={ ()=>{
                            window.scrollTo(0,0)
                            this.setState({
                              show : false
                            })
                          } }
                          href={v.link}
                          key={k}
                          target="_blank"
                          className={["Item", (this.props.selected===v['key'])?"Selected":""].join(" ")}
                        > 
                          {  i18n(v['key'])   } 
                        </a>
                      )
                    } else {
                        return (
                          <Link 
                            onClick={()=>{  
                              window.scrollTo(0,0) 
                              this.setState({
                                show : false
                              })
                              this.props.updatevalue({ selected  : v['key']})
                            }}
                            to={  v['key']  }
                            key={k}
                            className={["Item", (this.props.selected===v['key'])?"Selected":""].join(" ")}>
                              {  i18n(v['key'])  }
                          </Link>
                        )

                    }
                } )
            }





            <div className={['Languages']} >
              {
                this.props.languages.map(  
                  (v, k) => {
                      return (
                        <div 
                          key={k} 
                          onClick={()=>{
                            this.setState({
                              show : false
                            })
                            this.props.updatevalue({language : v.key})
                          }}
                          className={['Option', (this.props.language===v.key)?"Selected":""].join(" ")}>
                          {v['value']}
                        </div>
                      )
                    }
                )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header;


/*
<select className={['Languages']} value={this.props.language} onChange={ 
          (e)=>{
            this.props.updatevalue({
              language : e.target.value
            })
         } }>
            {
              this.props.languages.map(  
                (v, k) => {
                  return (
                    <option key={k} value={v.key} >
                      {v.name}
                    </option>
                  )
                }
              )
            }
        </select >
*/