import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import { FaRegEnvelopeOpen } from 'react-icons/fa';
import service from '../../public/service';
//

const sub = [
  //{"key" : "name"},
  {"key" : "email"}
]

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state={
      //name : "",
      email : "",
      isPrivacy : false,
      isTerms : false,
      tree : {},
    }
  }
  
  componentDidMount() {
    var _keys = {}, isPrivacy = false, isTerms = false
    for (var i in this.props.content) {
      
      if(  this.props.content[i].footer   ) {
        if( !(this.props.content[i].footer in _keys) ) {
          _keys[ this.props.content[i].footer ] = []
        }
        _keys[ this.props.content[i].footer ].push(   this.props.content[i]    )
      }
      if( this.props.content[i].key === "privacy") {
        isPrivacy = true
      }
      if( this.props.content[i].key === "terms") {
        isTerms = true
      }

    }
    this.setState({
      tree : _keys,
      isPrivacy : isPrivacy,
      isTerms : isTerms 
    })
  }

  componentDidUpdate(props, state) {
    //console.log("A")
  }

  render() {

    const {i18n} = this.props

    var showFooter = true

    for (var s of service.footer_excecptions) {
      if( window.location.pathname.indexOf(s) > -1 ) {
        showFooter = false
        break;
      }
    }

    return (
      <div className={["Footer", !showFooter?"Disabled":""].join(" ")}>
        <div className={["Footer-Top-Frame"].join(" ")}>

          <div className={["Lefts"].join(" ")}>


          </div>


          {
            this.props.config && this.props.config.subscribe &&
            <div className={["Right"].join(" ")}>

              <div className={["Title-Frame"].join(" ")}>
                <div className={["Icon-Wrapper"].join(" ")}>
                  <FaRegEnvelopeOpen className={['Icon'].join(" ") } />
                </div>
                <div className={["T-Wrapper"].join(" ")}>


                </div>
              </div>

              
            </div>
          }
        </div>


        <div className={["Footer-Bottom-Frame"].join(" ")}>
          <div className={["Copyright"].join(" ")}>
            {  i18n("copyright")  }
          </div>
          <div className={["Terms-Frame"].join(" ")}> 

          </div>
        </div>
      </div>
    )
  }
}

export default Footer;

