import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom'
import './index.scss';
import service from '../../public/service';
import {FaCar, FaSuperpowers, FaChargingStation} from 'react-icons/fa'


//https://www.figma.com/file/MUPrnWpC5aUAcsqoBjtQ3v/EV-Website-Design-Template-(Community)?type=design&node-id=15-58&mode=design&t=vgRhJS5HTjvwG518-0
class Product extends Component {
  constructor(props) {
    super(props)
    this.state={
 
    }
  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
    
  }

  render() {
    const {i18n, brands, updatevalue, language} = this.props

    return (
      <div className={["Product"].join(" ")}>
        <div className={["Content"].join(" ")}>   
          {
            brands.map( (v, k) => {
              return(
                <div className={["Section"].join(" ")} key={k}> 
                  <div className={["Title"].join(" ")}>
                    <img src={service.imageLocate( `brand/${v['key']}.png` ) } className={["Logo"].join(" ")} />
                    <div className={["Name"].join(" ")}> {  i18n( v['key'] )  } </div>
                  </div>
                  <div className='Wrapper'>
                    {
                      v['models'].map( (v1,k1) => {
                        return (
                          <div className='Vehicle' key={k1} onClick={()=>{
                            updatevalue({url : `${window.location.origin}/product/${v['key']}/${v1['key']}?header=false&footer=false&language=${language}`})
                          }}>
                            <div 
                              style={ {
                                backgroundImage : `url("${service.imageLocate( `brand/${v['key']}/${v1['key']}/${v1['cover']}`)}")`
                              } }
                              className='Product-Img'> </div>
                            <div className='Name'> {  i18n(`${v1['key']}_name`) } </div>
                          </div>
                        )
                      } )
                    }
                  </div>
                </div>
              )
            } )
          }
        </div>
      </div>
    )
  }

}

export default Product;