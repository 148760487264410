import React, { Component } from 'react';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom'

import Lottie from "lottie-react";
import Logo from "./assets/lottie/logo.json";

import './App.scss';
//import service from "./public/service"

import Header from "./Components/Header"
import Footer from "./Components/Footer"
import Cover from "./Components/Cover"
import Aboutus from "./Components/Aboutus"
import Product from "./Components/Products"
import Vehicle from "./Components/Vehicle"
import Popup from "./Components/Popup"

import { FaChevronCircleUp} from 'react-icons/fa';
import service from './public/service';


const exclude = [

]

const content = [
  {"key" : "home"},
  {"key" : "products"},
  {"key" : "aboutus"},
  {"key" : "contactus", "link" : "mailto:dr3minute@gmail.com"}
]

class App extends Component {

  constructor(props) {
    super(props)
    this.state={
      language : "en",
      languages : [],
      brands : [],
      isLoad : false,
      url : null,
      showheader : true,
      showfooter : true,
    }

    this.updatevalue = this.updatevalue.bind(this)
    this.getOS = this.getOS.bind(this)

    this.init = this.init.bind(this)
    this.i18n = this.i18n.bind(this)

  }

  componentDidMount() {
    const params = service.getParamQuery()
    var obj = {}
    if( "header" in params ) {
      obj['showheader'] = false
    }
    if( "language" in params ) {
      obj['language'] = params['language']
    }
    if( "footer" in params ) {
      obj['showfooter'] = false
    }
    if( Object.keys( obj ).length > 0 ) {
      this.setState(obj)
    }
    this.init()
  }

  componentDidUpdate(props, state) {
    
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
        //platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
        //platform = window.navigator?window.navigator.platform:?.userAgentData?.platform,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'windows';
    } else if (/Android/.test(userAgent)) {
      os = 'android';
    } else if (/Linux/.test(platform)) {
      os = 'linux';
    }
    return os;
  }

  updatevalue(obj, cb) {
    this.setState(obj, cb)
  }
  
  async init() {
    let data = await service.init()
    this.setState({  languages : data['languages'] , brands : data['brands'], isLoad : true, })
  }

  i18n(key) {
    const {language} = this.state 
    const _i18n = service._i18n
    return (_i18n[ language ] && _i18n[ language ][key])?_i18n[ language ][key]:key
  }

  render() {
    const { languages, isLoad, brands, url, showheader, showfooter, language} = this.state
    if( isLoad  ) {
      return(
        <div className={['App'].join(" ")} >
        
          <BrowserRouter>
            {
              showheader &&
              <Header 
                content={content}
                i18n={this.i18n}
                languages={ languages }
                updatevalue={this.updatevalue}
              />
            }
            <Routes>
              <Route exact path={ "/" } element={ 
                  <Cover 
                    page={"home"}
                    i18n={this.i18n}
                    languages={ languages }
                    brands={ brands}
                    updatevalue={this.updatevalue}
                  />
              }/>
              <Route exact path={ "/aboutus" } element={ 
                  <Aboutus 
                    page={"aboutus"}
                    i18n={this.i18n}
                    languages={ languages }
                    brands={ brands}
                    updatevalue={this.updatevalue}
                  />
              }/>

              <Route exact path={ "/products" } element={ 
                  <Product 
                    page={"brand"}
                    i18n={this.i18n}
                    languages={ languages }
                    language={language}
                    brands={ brands}
                    updatevalue={this.updatevalue}
                  />
              }/>

              <Route exact path={ "/product/:brand/:model" } element={ 
                  <Vehicle
                    page={"model"}
                    i18n={this.i18n}
                    languages={ languages }
                    language={language}
                    brands={ brands}
                    updatevalue={this.updatevalue}
                  />
              }/>

              {
                /*
                <Route exact path="/brand" element={<EShop brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} cart={this.state.cart} placeOrder={this.placeOrder} hasLogin={this.state.hasLogin} user={this.state.user} /> } />
                <Route exact path="/brand/:itemid" element={<EShop brands={brands} config={config} i18n={i18n} language={language} updatevalue={this.updatevalue} cart={this.state.cart} placeOrder={this.placeOrder} hasLogin={this.state.hasLogin} user={this.state.user}/> } />
                */
              }


              <Route path="/home" element={ <Navigate to="/" /> }/>



              <Route path="/*" element={ <Navigate to="/" /> }/>
            </Routes>
          
            {
              showfooter &&
              <Footer 
                i18n={this.i18n}
                languages={ languages }
                updatevalue={this.updatevalue}
              />
            }
              <Popup 
                url={url} 
                i18n={this.i18n}
                languages={ languages }
                brands={ brands}
                updatevalue={this.updatevalue}
              />


          </BrowserRouter>

        </div>
      )
    } else {
      return <div className='App Loading'>{"Loading ...".toUpperCase()}</div>
    }
  }
}

export default App;


