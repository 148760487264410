import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './index.scss';
import {FaShare} from 'react-icons/fa'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import service from '../../public/service';


//https://www.figma.com/file/MUPrnWpC5aUAcsqoBjtQ3v/EV-Website-Design-Template-(Community)?type=design&node-id=15-58&mode=design&t=vgRhJS5HTjvwG518-0
class Vehicle extends Component {
  constructor(props) {
    super(props)
    this.state={
      brand : null,
      model : null,
      selectedBrand : null,
      selectedModel : null
    }
    this.getParams = this.getParams.bind(this)
    this.copy = this.copy.bind(this)
  }
  
  componentDidMount() {
    this.getParams()
    this.props.updatevalue({ selected : this.props.page  })
  }

  getParams() {
    const params =  window.location.pathname
    const names = params.split("/")
    const {brands} = this.props
    if( names.length === 4 ) {
      try {
        var selectedBrand = brands.filter( (v)=> v['key'] === names[2] )[0]
        var selectedModel = selectedBrand['models'].filter( (v) => v['key'] === names[3] )[0]
        this.setState({
          //brand : names[2],
          //model : names[3],
          selectedBrand : selectedBrand,
          selectedModel : selectedModel
        })
      } catch(e) {
        console.log(e)
      }
    }
  }

  copy() {
    //const {language} = this.props
    //const { selectedBrand, selectedModel } = this.state
    //const link = `${window.location.origin}/product/${selectedBrand['key']}/${selectedModel['key']}?header=false&footer=false&language=${language}`
    var copyText = document.getElementById("CopyLink");
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    alert("Copied the text: " + copyText.value);
  }

  render() {
    const {i18n, brands, language }= this.props
    const { selectedBrand, selectedModel } = this.state
    if( selectedBrand && selectedModel ) {
      return (
        <div className={["Model"].join(" ")}>
          <input className="Hidden" id={"CopyLink"} value={`${window.location.origin}/product/${selectedBrand['key']}/${selectedModel['key']}?header=false&footer=false&language=${language}`}  />
          <div className={["Title"].join(" ")}> 
            <img className={["Logo"].join(" ")} src={ service.imageLocate(`/brand/${selectedBrand['key']}.png`) } />
            <div className={["Name"].join(" ")}> { i18n(`${selectedModel['key']}_name`).toUpperCase() } </div>
            <FaShare className="Fa" onClick={ ()=>{
              this.copy()
            } }/>
          </div>

          <div className={["Content"].join(" ")}>
            <div className={["Section"].join(" ")}>
              <img className={["Cover"].join(" ")}
                src={service.imageLocate(`brand/${selectedBrand['key']}/${selectedModel['key']}/${selectedModel['cover']}`)  }  />
            </div>
          </div>

          <div className={["Content", "P2"].join(" ")}>
            <div className={["Section"].join(" ")}>
              <div className={["Desc"].join(" ")}>
                {   
                  i18n(`${selectedModel['key']}_desc`).split("\n").map( (v, k) => {
                    return (
                      <p key={k}>{v}</p>
                    )
                  } )
                }
              </div>
            </div>
          </div>

          {
            selectedModel['images'].map( (v, k) => {
              return (
                <div className={["Content", "P3"].join(" ")} key={k}>
                  <div className={["Section"].join(" ")}>
                    <div className={["Title"].join(" ")}> 
                      <div className={["Name"].join(" ")}> 
                        { i18n(`${ v['key'] }`) } 
                      </div>
                    </div>
                    <div className={["Masonry"].join(" ")}> 
                      <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                        <Masonry>
                        {
                          v['images'].map( (val, key) => {
                            return (
                              <img key={key} 
                                src={ service.imageLocate(`brand/${selectedBrand['key']}/${selectedModel['key']}/${val}`)  }
                              />
                            )
                          })
                        }
                        </Masonry>
                      </ResponsiveMasonry>
                    </div>
                  </div>
                </div>
              )
            } )
          }

        </div>
      )
    } else {
      return null
    }
  }

}

export default Vehicle;


/*
<ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                <Masonry>
                  {
                    this.props.body.images.map( (v,k) => {
                      return(
                        <img 
                          onClick={ (e)=>{
                            this.open(k)
                          } }
                          key={k}
                          className={["Image"].join(" ")} 
                          src={  window.location.origin + "/resources/img/brand/" + this.props.parent.key + "/"  + this.props.body.id + "/" + v } />
                      )
                    } )
                  }
                </Masonry>
            </ResponsiveMasonry>
*/