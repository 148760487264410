


var colors = {
  //font : window.colors?window.colors.font:"14px/1.5 'Noto Sans TC', sans-serif",
  font : window.colors?window.colors.font:'"Verdana","Noto Sans TC","Noto Sans SC"',
  /*FRAME COLORS*/
  bgcolor: window.colors?window.colors.bgcolor:"#ffffff",
  whiteColor: window.colors?window.colors.whiteColor:"#ffffff",
  darkColor: window.colors?window.colors.darkColor:"#000F08",
  greyColor: window.colors?window.colors.greyColor:"#EEEEEE",
  frameColor: window.colors?window.colors.frameColor:"#BDFF91",

  accentColor : window.colors?window.colors.accentColor:"#618b25",
  accentColor2 : window.colors?window.colors.accentColor2:"#9B7E46",
  accentColor3 : window.colors?window.colors.accentColor3:"#E2EFDE",

  frameColorSub : window.colors?window.colors.frameColorSub:"#252422",
  frameColor2: window.colors?window.colors.frameColor2:"#F4B266",
  frameColor2Sub : window.colors?window.colors.frameColor2Sub:"#E2EFDE",

  /*FONT COLORS*/
  textColor: window.colors?window.colors.textColor:"#333333",
  textColorWhite: window.colors?window.colors.textColorWhite:"#FFFFFF",
  textColorLight: window.colors?window.colors.textColorLight:"#999999",
};

export default colors;
