import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';
import { Link } from 'react-router-dom'
import './index.scss';
import service from '../../public/service';
import {FaCar, FaSuperpowers, FaChargingStation} from 'react-icons/fa'

const icons = [
  {"key" : "cv_car_dealer" , "icon" : FaCar},
  {"key" : "cv_car_repair" , "icon" : FaSuperpowers},
  {"key" : "cv_power_grid" , "icon" : FaChargingStation},
]

//https://www.figma.com/file/MUPrnWpC5aUAcsqoBjtQ3v/EV-Website-Design-Template-(Community)?type=design&node-id=15-58&mode=design&t=vgRhJS5HTjvwG518-0
class Cover extends Component {
  constructor(props) {
    super(props)
    this.state={
      offsetY : false
    }
  }
  
  componentDidMount() {
    this.props.updatevalue({ selected : this.props.page  })
    
  }

  render() {
    const {i18n, brands} = this.props
    /*
    <a href="https://www.freepik.com/free-vector/electric-vehicles-isometric-design-concept_6438374.htm#fromView=search&page=1&position=23&uuid=0325113b-cc70-4541-ac41-ae1ea574cce9">Image by macrovector on Freepik</a>
    */
    return (
      <div className={["Cover"].join(" ")}>

        <img className={["Img"].join(" ")} src={`${window.location.origin}/resources/img/cover/cover.webp`}/>

        <div className={["Content"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <div className={["H1"].join(" ")}> { i18n("CV_Header1") } </div>
            <div className={["Icon-Holder"].join(" ")}>
              {
                icons.map( (v,k) => {
                  return (
                    <div className={["Icon"].join(" ")} key={k}>
                      <v.icon className={["Fa"].join(" ")}/>
                      <div className={["Name"].join(" ")} > { i18n(v['key']).toUpperCase() } </div>
                    </div>
                  )
                } )
              }
            </div>
          </div>
        </div>

        <div className={["Content", "Message"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <div className={["H1"].join(" ")}> { i18n("CV_Header2") } </div>
            <p className={["Msg"].join(" ")}>
              { i18n("CV_Msg_2") }
            </p>
          </div>
        </div>



        <div className={["Content",  "Brand"].join(" ")}>
          <div className={["Section"].join(" ")}>
            <div className={["H1"].join(" ")}> { i18n("CV_Header3") } </div>
            <div className={["Icon-Holder"].join(" ")}>
              {
                brands.map( (v,k) => {
                  return (
                    <img
                      className={["Brand-Img"].join(" ")}
                      src={service.imageLocate( `brand/${v['key']}.png` ) } key={k}/>
                  )
                } )
              }
            </div>
          </div>
        </div>

      </div>
    )
  }

}

export default Cover;