var service = {
  _i18n : {},
  header_excecptions : [],
  header_zero_content : [],
  footer_excecptions : [],

  "clearAllData" : () => {
    window.localStorage.clear()
  },

  "storeData" : async (key,value) => {
    try {
      var attr = `@`
      const jsonValue = JSON.stringify(value);
      await window.localStorage.setItem( (attr+key), jsonValue);
    } catch (e) {
      await window.localStorage.setItem(key, value);
    }
  },

  "getData" : async (key, placeholder=[]) => {
    try {
      var attr = `@`
      const jsonValue = await window.localStorage.getItem( (attr+key)  );
      if ( jsonValue !== null ) {
        return JSON.parse(jsonValue)
      } else {
        return null
      }
    } catch (e) {
      return placeholder
    }
  },

  fetchFile : (str) => {
    var url = window.location.origin + "/resources/" + str
    return fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } ).then(
      (res) => res.json()
    )
  },

  init : async () => {
    var url = window.location.origin + "/resources/config.json"
    var res = await fetch( url ,{
      headers: {
        'Cache-Control': 'no-cache'
      }
    } )
    try {
      let result = await res.json()
      var i18n = {}
      for( var l of result['languages']) {
        var url = `${window.location.origin}/resources/i18n/${l['key']}.json`
        var res = await fetch( url ,{
          headers: {
            'Cache-Control': 'no-cache'
          }
        } )
        let lang = await res.json()
        i18n[ l['key'] ] = lang
      }
      service._i18n = i18n
      return await result
    } catch (e) {
      console.log(e)
      return []
    }
  },

  imageLocate : (name) => {
    return `${window.location.origin}/resources/img/${name}`
  },

  getParamQuery : () => {
    var params = {}
    if( window.location.search.length > 0 ) {
      try {
        const str = window.location.search.substring( 1, window.location.search.length )
        const arr = str.split("&")
        //var session_key = null, user_id = null, redirect = false
        for( var s of arr) {
          const _a = s.split("=")
          if( _a.length === 2 ) {
            params[ _a[0] ] = _a[1]
          } else {
            params[ _a[0] ] = s.replace( _a[0]+"=", "" )
          }
        }
        return params
      } catch(e) {
        return params
      }
    } else {
      return params
    }
  },

}

export default service;
